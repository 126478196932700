.navbar {
  position: absolute;
  width: 100%;
  z-index: 50;
}

.topBar {
  background: transparent;
  transition: all 0.3s ease;
}

.mainNav {
  @apply transition-all duration-300;
}

.mainNav-scrolled {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.mainNav-transparent {
  background: transparent;
}

.dropdown-menu {
  @apply absolute right-0 mt-2 w-64 bg-white rounded-lg shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none 
    divide-y divide-gray-100 p-2;
}

.dropdown-item {
  @apply flex items-start p-3 rounded-md hover:bg-gray-50 transition-colors;
}

.search-overlay {
  @apply fixed inset-0 bg-black bg-opacity-50 transition-opacity;
}

.search-modal {
  @apply fixed inset-x-0 top-0 z-50 bg-white shadow-xl transform transition-transform;
}

/* Add more custom styles as needed */
